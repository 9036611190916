.movieCard {
    display: grid;
    grid-template-columns: 135px auto;
    border-radius: 10px;
    background-color: #f4f4f4;
    margin: 5px 2px;
}

.posterContainer {
    align-items: center;
    justify-content: center;
    line-height: 198px;
    position: relative;

    a {
        pointer-events: none;
    }

    .moviePoster {
        width: 100%;
    }

    .fallbackPoster {
        display: block;
        height: 100%;
        background-color: #ff8561;
        border-radius: 10px;
    }

    img {
        max-height: 200px;
        max-width: 135px;
        border-radius: 10px;
        vertical-align: middle;
    }
}

.infoContainer {
    padding-left: 8px;

    * {
        margin: 1px 0;
    }

    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 190px;
}

.titleRow {
    a {
        display: inline-block;
        margin-top: 5px;
        line-height: 22px;
        max-width: 95%;
        font-size: 20px;
        font-weight: bold;
        color: black;
        text-decoration: none;
        cursor: default;
        pointer-events: none;
    }

    .fa-link {
        visibility: hidden;
    }
}

.rating {
    border: solid 1px lightgrey;
    padding: 0px 4px;
}

.metaDataRow>span:not(:first-of-type):before {
    content: " ‧ ";
}

.amcLogo {
    width: 40px;
    height: 12px;
    padding-top: 3px;
}

.scoresRow {
    display: grid;
    grid-template-columns: 80px auto;
    max-width: 600px;
    margin: -4px 0 -3px 0;
    min-height: 7px;

    .score {
        padding-left: 8px;
        font-size: 15px;
        font-weight: bold;
        vertical-align: top;
    }

    .tomatoCol {
        img {
            width: 30px;
            height: 30px;
        }

        .score {
            display: inline-block;
            margin-top: 6px;
        }
    }

    .imdbCol {
        justify-self: center;
        margin-top: 8px;

        img {
            width: 40px;
            height: 22px;
        }
    }
}

.subInfoContainer {
    position: relative;
    height: 100%;
    overflow-y: hidden;
    cursor: default;
}

.description {
    font-size: 12px;
    padding-right: 5px;
    cursor: pointer;
    width: 95%;

    @media (max-width: 600px) {
        font-size: 10px;
    }
}

.mainView {
    .expandableSection {
        position: absolute;
        animation: .1s slideDown 0s forwards;
    }

    .youtubeIcon {
        animation: .3s slideAway 0s forwards;
        display: none;
        position: absolute;
        left: 0;
        width: 80%;
        margin-left: 7%;
    }
}

.extraView {
    .posterContainer {
        border-radius: 10px;
        animation: 1s flipCard 0s forwards;

        img.moviePoster {
            opacity: .4;
            position: absolute;
            height: 100%;
        }

        img.youtubeIcon {
            display: inline-block;
            position: relative;
            width: 80%;
            margin-left: 7%;
            opacity: 80%;
            animation: 1s appearOnFlip 0s forwards;

            &:hover,
            &:active {
                opacity: 100%;
            }
        }
    }

    .movieTitle,
    .scoresRow {
        zoom: .8;
    }

    a {
        pointer-events: auto;
        cursor: pointer;
        position: relative;

        &:hover {
            color: #7a1a9c;
        }


        .fa-link {
            position: absolute;
            color: #7a1a9c;
            padding-left: 5px;
            font-size: 12px;
            visibility: visible;
        }
    }

    .metaDataRow {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s forwards;
    }

    .expandableSection {
        position: absolute;
        background-color: #f4f4f4;
        height: 100%;
        animation: .1s slideUp 0s forwards;
        overflow-y: scroll;

        .scoresRow {
            min-height: 25px;
        }

        >.description {
            font-size: 13px;
            line-height: 16px;
        }
    }

    @keyframes slideUp {
        10% {
            top: initial;
        }

        50% {
            top: 30%;
        }

        100% {
            top: 0%;
        }
    }

    @keyframes slideDown {
        10% {
            top: 0%;
        }

        50% {
            top: 30%;
        }

        100% {
            top: initial;
        }
    }

    @keyframes slideAway {
        0% {
            display: inline-block;
            top: 25%;
        }

        80% {
            top: 80%;
            opacity: 0%;
        }

        90% {
            display: none;
        }
    }

    @keyframes appearOnFlip {
        0% {
            opacity: 0%;
            transform: rotateY(200deg);
        }

        30% {
            opacity: 0%;
        }

        100% {
            transform: rotateY(360deg);
        }
    }

    @keyframes flipCard {
        0% {
            transform: rotateY(200deg);
        }

        100% {
            transform: rotateY(360deg);
            background-color: black;
        }
    }
}

.featuredStar {
    color: #ffba00;
    font-size: 20px;
    margin-top: 4px;
    margin-left: 3px;
    position: absolute;
}

// Screen overlay to close and show one setting menu at a time
#overlay {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
}

.settingsContainer {
    z-index: 1;
    position: relative;
    float: inline-end;
}

.openSettingsBtn {
    position: absolute;
    right: 0;
    color: grey;
    font-size: 20px;
    padding-top: 10px;
    padding-right: 10px;
    text-align: right;
    width: 30px;
    display: inline;

    &:hover {
        color: black;
    }
}

.settingsMenu {
    position: relative;
    z-index: 2;

    .actions {
        position: absolute;
        right: 4px;
        width: 200px;
        box-shadow: -8px 8px 12px 1px #aaaaaa;
        background-color: rgb(235, 235, 235);
    }

    .fas {
        padding-left: 10px;
        padding-right: 20px;
        color: grey;
    }

    button {
        padding: 13px 10px;
        font-size: 16px;
        width: 100%;
        border: none;
        cursor: pointer;
        background-color: white;
        text-decoration: none;
        text-align: left;
        margin: .5px;

        &:hover {
            background-color: #e6fff5;
        }
    }
}

.loadingRing {
    margin-left: 30%;

    span {
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring .8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: lightgrey transparent transparent transparent;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}