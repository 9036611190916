.header {
    display: grid;
    grid-template-rows: 80px auto;
    grid-template-columns: 85px auto;

    background-color: #6dc9a5;
    padding-top: 10px;

    img {
        width: 65px;
        padding: 8px;
    }
}

.searchBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    svg {
        width: 24px;
        height: 24px;
    }

    #searchQueryInput {
        width: 70%;
        height: 2.3rem;
        background: #f5f5f5;
        outline: none;
        border: none;
        border-radius: 1.625rem;
        padding: 0 3.5rem 0 1.5rem;
        font-size: 1rem;
    }

    #searchQuerySubmit {
        width: 60px;
        height: 25px;
        margin-left: -55px;
        padding-right: 70px;
        background: none;
        border: none;
        outline: none;
    }

    #searchQuerySubmit:hover {
        cursor: pointer;
    }
}

.tabs {
    grid-column: span 2;
    border: 1px solid #ccc;
    box-sizing: border-box;
    background-color: #f1f1f1;
    width: 100%;

    button {
        border: none;
        cursor: pointer;
        font-size: 15px;
        padding: 14px 16px 10px 16px;
        transition: 0.3s;
        color: black;

        &:hover {
            background-color: #ddd;
            border-bottom: solid 8px #ddd;
        }

        &.active {
            background-color: #ccc;
            border-bottom: solid 8px #5ebf99;
        }
    }
}