@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

@media (max-width: 600px) {
    * {
        zoom: .994;
        -webkit-tap-highlight-color: transparent;
    }
}

body {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}

.container {
    max-width: 1100px;
    margin: 0px auto;
    overflow: auto;
    min-height: 300px;
}

.amcBtnContainer {
    display: inline-block;
    width: 100%;
    margin-top: 10px;

    button {
        color: red;
        font-size: 14px;
        padding: 4px 8px;
        border-radius: 5px;
        border: 1px solid darkgray;
        background-color: white;
        float: right;
        margin-right: 3px;
    }
}

.amcOverlay {
    background-color: rgba(black, 0.7) !important;
}

.amcModal {
    padding: 0 !important;
    margin: 10px 6px !important;
    background-color: transparent !important;
    overflow: hidden !important;

    @media (max-width: 600px) {
        >* {
            zoom: 0.95;
        }
    }

    .movies {
        margin-top: 40px;
        overflow: scroll;
        height: 95%;
    }

    .amcModalLabel {
        position: fixed;
        top: -15px;
        color: white;
        font-size: 18px;
        background-color: #f50127;
        padding: 0px 10px;
        border-radius: 5px;
        margin-left: 5px;
    }

    .closeModalBtn {
        position: fixed;
        color: white;
        font-size: 18px;
        top: -12px;
        right: 10px;
        margin-bottom: 10px;
        padding: 5px 0 0 50px;
    }
}

::-webkit-scrollbar {
    display: none;
}

.pageLoadRing {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    span {
        display: inline-block;
        box-sizing: border-box;
        width: 100px;
        height: 100px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring .8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #6dc9a5 transparent transparent transparent;
    }
}

.previewDisclaimer {
    color: white;
    background-color: rgb(255, 86, 86);
    position: fixed;
    z-index: 1;
    width: 100%;

    * {
        display: inline-block;
    }

    p {
        padding: 5px 20px;
        margin: 0;
        width: 80%;
    }

    a {
        color: white;

        &:hover {
            color: #7a1a9c;
        }
    }

    button {
        position: absolute;
        color: white;
        font-size: 18px;
        right: 0;
        margin-right: 5px;
        padding-left: 20px;
        border: none;
        background: none;
    }
}